<template>
  <div v-if="typeTableData.length > 0">
    <!-- 费用科目说明 -->
    <el-dialog
      width="70%"
      title="费用科目说明"
      append-to-body
      :visible.sync="isDialogShow"
      class="typeDialog"
      :before-close="closeDialog"
    >
      <el-table
        :data="typeTableData"
        size="normal"
        style="width: 100%"
        row-key="id"
        border
        default-expand-all
        height="570px"
        :cell-class-name="typeTableCellStyle"
        :span-method="arraySpanMethod"
      >
        <el-table-column prop="dictName" label="科目一" width="170px"> </el-table-column>
        <el-table-column label="科目二" :show-overflow-tooltip="false" width="170px">
          <template slot-scope="scope">
            <el-table
              size="normal"
              v-if="scope.row.hasChildren2"
              :data="scope.row.dictDataTwoList"
              style="width: 100%; height: 100%"
              :show-header="false"
              :cell-class-name="typeTableCellStyle"
              :span-method="arraySpanMethod2"
            >
              <el-table-column prop="dictName" width="170px" :show-overflow-tooltip="false">
                <template slot-scope="scope">
                  {{ scope.row.dictName }}
                </template>
              </el-table-column>
              <el-table-column :show-overflow-tooltip="false" width="170px">
                <template slot-scope="scope">
                  <!-- scope.row指代的是二级科目 -->
                  <el-table
                    size="normal"
                    v-if="scope.row.hasChildren3"
                    :data="scope.row.dictDataThreeList"
                    style="width: 100%; height: 100%"
                    :show-header="false"
                    :cell-class-name="typeTableCellStyle"
                  >
                    <el-table-column prop="dictName" width="170px" :show-overflow-tooltip="false">
                      <template slot-scope="scope">
                        {{ scope.row.dictName }}
                      </template>
                    </el-table-column>
                    <el-table-column v-if="scope.row.hasRemark3" prop="remark" label="使用说明">
                    </el-table-column>
                  </el-table>
                  <div v-else></div>
                </template>
              </el-table-column>
              <el-table-column v-if="scope.row.hasRemark2" prop="remark" label="使用说明">
              </el-table-column>
            </el-table>
            <div v-else></div>
          </template>
        </el-table-column>
        <el-table-column label="科目三" :show-overflow-tooltip="false" width="170px">
        </el-table-column>
        <el-table-column prop="remark" label="使用说明"> </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isDialogShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typeTableData: [],
    }
  },
  watch: {
    isDialogShow(newVal) {
      if (newVal) {
        this.$emit('changeLoading', true)
        // 显示费用科目说明弹框
        this.$api.dict
          .getTreeDataList({ dictType: 'CBFYZC' })
          .then(res => {
            this.typeTableData = res.data
            this.typeTableData.forEach((item, index) => {
              item.id = index + ''
              if (item.dictDataTwoList && item.dictDataTwoList.length !== 0) {
                item.hasChildren2 = true
                const find = item.dictDataTwoList.find(item => item.remark)
                if (find) {
                  item.hasRemark2 = true
                }
                item.dictDataTwoList.forEach((item2, index2) => {
                  item2.id = String(index) + '&' + String(index2)
                  if (item2.dictDataThreeList && item2.dictDataThreeList.length !== 0) {
                    item2.hasChildren3 = true
                    item2.dictDataThreeList.forEach((item3, index3) => {
                      item3.id = String(index) + '&' + String(index2) + '&' + String(index3)
                    })
                    const find = item2.dictDataThreeList.find(item => item.remark)
                    if (find) {
                      item2.hasRemark3 = true
                    }
                  } else {
                    item2.hasChildren3 = false
                  }
                })
              } else {
                item.hasChildren2 = false
              }
            })
            this.$emit('changeLoading', false)
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
  },
  methods: {
    // 费用科目类型说明弹框表格样式
    typeTableCellStyle({ row, columnIndex, column }) {
      let styleArr = ['cellTitleAlign']
      if (columnIndex == 1) {
        styleArr.push('cellName')
      }

      if ((row.hasChildren2 || row.hasChildren3) && columnIndex == 1) {
        styleArr.push('clearBorder')
      }
      if (column.label == '使用说明') {
        styleArr.push('remark-border')
      }
      return styleArr.join(' ')
    },

    // 费用科目类型说明弹框合并单元格情况
    arraySpanMethod({ row, columnIndex }) {
      if (row.hasChildren2) {
        if (columnIndex == 1) {
          if (row.hasRemark2) {
            return [1, 3]
          } else {
            return [1, 2]
          }
        } else if (columnIndex == 2) {
          return [0, 0]
        }
      }
    },

    // 科目二表格合并
    arraySpanMethod2({ row, columnIndex }) {
      if (row.hasChildren3) {
        if (columnIndex == 1) {
          if (row.hasRemark3) {
            return [1, 2]
          } else {
            return [1, 1]
          }
        } else if (columnIndex == 2) {
          return [0, 0]
        }
      }
    },

    closeDialog(e) {
      this.$emit('update:isDialogShow', false)
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/.cellName {
  .cell {
    padding: 0;
  }
  padding: 0 !important;
}

.typeDialog {
  /deep/ .el-table th {
    border-color: #303133 !important;
    box-sizing: border-box;
  }
  /deep/ .el-table td {
    border-color: #303133 !important;
    height: 43px;
    line-height: 43px;
    box-sizing: border-box;
  }
  /deep/ .el-table--group,
  /deep/ .el-table--border {
    border: 0.1px solid #303133 !important;
    box-sizing: border-box;
  }
  /deep/.cellTitleAlign {
    text-align: center;
  }
  /deep/.clearBorder {
    border-right: none !important;
  }
  /deep/.remark-border {
    border: 1px solid #efefef !important;
  }
}
</style>
